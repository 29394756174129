import type { Writable } from 'svelte/store';
import { writable, get } from 'svelte/store';
import type { Play3Page } from 'common/render';
import * as Cmd from '../common/commands.js';
import * as Comp from '../common/components.js';
import type { Command, WorldSync, Transaction, Entity } from 'common/world';
import { World, ComponentKind } from 'common/world';
import { default_page_data } from 'v2/render';
import { play3_send_message } from 'v2/send';

// play3 is not reactive, the UI should only interact with the stores.
// Utility functions here are ok.
let play3: World = new World();
export let play3_uuid: Writable<string> = writable(play3.uuid);
export let play3_transactions: Writable<Array<Transaction>> = writable(
  play3.transactions
);
export let play3_entities: Writable<Array<Writable<Entity>>> = writable(
  play3.entities.map((e) => (e ? writable(e) : null))
);
export let play3_page: Writable<Play3Page> = writable(default_page_data.play3_page);

export function play3_command<T extends Command>(command: T) {
  return play3_send_message('gh:s:play3', play3.client_send_command(command));
}

export function on_play3_page(page: Play3Page) {
  play3_page.update(() => page);
  play3_load(page.play3);
}

export function on_play3(data: any) {
  if (data.play3) play3_load(data.play3);
  else if (data.sync) play3_sync(data.sync);
}

function play3_load(_play3: World) {
  play3 = new World(_play3);
  play3_uuid.update(() => play3.uuid);
  play3_transactions.update(() => play3.transactions);
  play3_entities.update(() => play3.entities.map((e) => (e ? writable(e) : null)));
}

function play3_sync(sync: WorldSync) {
  if (!play3) return;
  if (play3.uuid !== get(play3_uuid)) return;
  const changed_ids = play3.client_receive_sync(sync);
  play3_transactions.update(() => play3.transactions);
  if (changed_ids) {
    // For each entity changed in the sync transactions, update the entity in play3_entities.
    const entity_stores = get(play3_entities);
    let array_changed = false;
    changed_ids.sort().forEach((id) => {
      const entity = play3.entity(id);
      if (entity && entity_stores[id]) {
        entity_stores[id].update(() => entity);
      } else {
        array_changed = true;
        entity_stores[id] = entity ? writable(entity) : null;
      }
    });
    if (array_changed) play3_entities.update((arr) => arr);
  }
}

export function component<T>(entity: Entity, component_kind: ComponentKind): T {
  return entity?.components?.[component_kind] as T;
}

export function current_transaction_id(entity: Entity): Comp.TransactionId {
  return component<Comp.TransactionId>(entity, ComponentKind.CURRENT_TRANSACTION_ID);
}

export function next_transaction_id(entity: Entity): Comp.TransactionId {
  return component<Comp.TransactionId>(entity, ComponentKind.NEXT_TRANSACTION_ID);
}

export function scenario(entity: Entity): Comp.Scenario {
  return component<Comp.Scenario>(entity, ComponentKind.SCENARIO);
}

export function round(entity: Entity): Comp.Round {
  return component<Comp.Round>(entity, ComponentKind.ROUND);
}

export function image_info(entity: Entity): Comp.ImageInfo {
  return component<Comp.ImageInfo>(entity, ComponentKind.IMAGE_INFO);
}

export function figure_info(entity: Entity): Comp.FigureInfo {
  return component<Comp.FigureInfo>(entity, ComponentKind.FIGURE_INFO);
}

export function map_position(entity: Entity): Comp.MapPosition {
  return component<Comp.MapPosition>(entity, ComponentKind.MAP_POSITION);
}
